/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('#payment-form').is('*')) {
    $(document).on('click', '#payment-form-buy-button', function() {
      if (!document.forms['payment-form'].checkValidity()) {
        document.forms['payment-form'].reportValidity();
        return;
      }

      $.ajax({
        type: 'GET',
        url: '/cart/credit_verify_recaptcha',
        data: { token: ($('#g-recaptcha-response-data-newcredit').val() || $("#card-type-new [name='g-recaptcha-response']").val()) },
        dataType: 'html',
        success(res) {
          $('#payment-form .js-recaptcha-tag').html(res);

          const cardholder_name_regex = /^[ -~]*$/;
          if (document.getElementById('cardholder_name').value.match(cardholder_name_regex) === null) {
            alert("カード名義は半角英数字記号で入力してください。");
            return;
          }

          const $submitButton = $('[name=submit-button]');
          $submitButton.prop('disabled', true);

          const data = {};
          data.token_api_key = document.getElementById('token_api_key').value;

          if (document.getElementById('card_number')) {
            data.card_number = document.getElementById('card_number').value;
          }

          if (document.getElementById('date_month')) {
            data.card_expire =
              ('0' + document.getElementById('date_month').value).slice(-2) + "/" +
                document.getElementById('date_year').value.substr(2, 2);
          }

          if (document.getElementById('card_cvc')) {
            data.security_code = document.getElementById('card_cvc').value;
          }

          // https://drive.google.com/file/d/1ODw7NlJB6zcN8zmyXN3XS0TA1kmdpAkC/view?usp=sharing
          if (document.getElementById('cardholder_name')) {
            data.cardholder_name = document.getElementById('cardholder_name').value;
          }

          data.lang = 'ja';

          const url = document.getElementById('token_api_url').value;

          const xhr = new XMLHttpRequest();
          xhr.open('POST', url, true);
          xhr.setRequestHeader('Accept', 'application/json');
          xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
          xhr.addEventListener('loadend', function() {
            if (xhr.status === 0) {
              alert("トークンサーバーとの接続に失敗しました");
              $submitButton.prop('disabled', false);
              return;
            }

            const response = JSON.parse(xhr.response);
            if (xhr.status === 200) {
              document.getElementById('card_number').value = "";
              document.getElementById('date_month').value = "";
              document.getElementById('date_year').value = "";
              document.getElementById('card_cvc').value = "";
              document.getElementById('cardholder_name').value = "";
              document.getElementById('card_token').value = response.token;
              document.getElementById('mask_card_no').value = response.req_card_number;
              window.showLoading();
              return document.forms['payment-form'].submit();
            } else {
              // お支払いの最終確認モーダルを元に戻す
              $('.modal button[type=submit]').prop('disabled', false);
              $('.modal').modal('hide');

              alert(response.message);
              return $submitButton.prop('disabled', false);
            }
          });

          return xhr.send(JSON.stringify(data));
        },
        error(res) {
          return location.reload();
        }
      });
      return false;
    });

    return $('input[name="card_id"]:radio').change(function() {
      $('.js-registered-card-submit').removeClass('d-none');
      return $('.js-card-not-selected-btn').addClass('d-none');
    });
  }
});
